import appleBadge from '@/assets/apple-store-badge.svg';
import bingoScreenshot from '@/assets/bingo_screenshot.png';
import AppButton from '@/components/AppButton';
import { GTMContext } from '@/context/GTMContext';
import { mobile_app_store_bingo_grapes_home, mobile_download_apk_bingo_grapes_home } from '@/utils/GTMFunctions';
import Image from 'next/image';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';

export default function Home() {
  const [browserInfo, setBrowserInfo] = useState('');
  const [androidVersion, setAndroidVersion] = useState(12);
  const { setDataLayer } = useContext(GTMContext);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      setBrowserInfo('Android');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setBrowserInfo('IOS');
    } else {
      setBrowserInfo('Web');
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]+)/);
      if (match) {
        setAndroidVersion(parseFloat(match[1]));
      }
    }
  }, []);

  const APKLink =
    androidVersion <= 7
      ? `https://bingo.joingrapes.com/apk/grapesbingo-latest-armeabi-v7.apk`
      : `https://bingo.joingrapes.com/apk/grapesbingo-latest-arm64-v8.apk`;

  return (
    <div className="home">
      <div className="home__container">
        <div className="home__container__text">
          <div className="home__container__text__title">Welcome to Grapes Arcade and Grapes Bingo!</div>
          <div className="home__container__text__subtitle">
            Play now with $GRAPE, $APE, and traditional currency. Use supported NFTs within the app for an enhanced
            gaming experience. Let the fun begin!
          </div>
          <div className="home__container__text__download">
            <div className="home__container__text__download__button">
              {browserInfo !== 'IOS' && (
                <Link href={APKLink}>
                  <AppButton onClick={() => setDataLayer(mobile_download_apk_bingo_grapes_home())}>
                    Download APK
                  </AppButton>
                </Link>
              )}
            </div>
            <div className="home__container__text__download__store">
              {browserInfo !== 'Android' && (
                <Link
                  onClick={() => setDataLayer(mobile_app_store_bingo_grapes_home())}
                  href="https://apps.apple.com/us/app/grapes-bingo/id6478917342"
                  target="_blank"
                >
                  <Image className="apple" src={appleBadge} alt="google-play-badge" />
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="home__container__image">
          <Image src={bingoScreenshot} alt="home" />
        </div>
      </div>
    </div>
  );
}
